

/* tooltip */

.introjs-tooltip {
    border: none !important;
    border-radius: 10px !important;
    padding: 7px !important;
    min-width: 320px !important;
    max-width: 320px !important;
}

.introjs-arrow {
    border: none !important;
}

/* body */

.introjs-tooltiptext { 
    padding: 0px 15px !important;
    padding-bottom: 10px !important;
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

.introjs-tooltiptext p { 
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
    font-weight: 400 !important;
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New" !important; 
    margin-bottom: 10px !important;
}

.introjs-tooltiptext a { 
    margin-bottom: 10px !important;
}

/* header */

.introjs-tooltip-header { 
    all: unset !important;
    position: absolute;
}

.introjs-tooltip-title { 
    padding: 10px 15px !important;
    width: 100% !important;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

/* nav buttons */

.introjs-skipbutton {
    right: 6px !important;
    top: 5px !important;
    width: 30px !important;
    height: 30px !important;
    color: #000000 !important;
    font-size: 22px !important;
    /* background: rgb(230, 230, 230); */
    font-weight: 400 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 6px;
}

.introjs-skipbutton:hover { 
    background: rgb(228, 228, 228);
}

/* progres bar */

.introjs-bullets { 
    display: none !important;
}

.introjs-tooltipbuttons { 
    border: none !important;
    padding: 8px 10px !important;
}

.introjs-tooltipbuttons a { 
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    color: white !important;
    border-radius: 8px !important;
    box-shadow: none !important;
    background: rgb(18, 93, 255) !important;
    font-weight: 500 !important;
}

.introjs-button { 
    text-shadow: none !important;
}


/* hint */

.introjs-hint-pulse { 
    background-color: rgb(247, 103, 103) !important;
    animation: introjspulse2 2s infinite !important;
}

@keyframes introjspulse2 {
    0% {
        transform: scale(.95);
        box-shadow: 0 0 0 0 rgb(248, 143, 143);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px transparent
    }

    100% {
        transform: scale(.95);
        box-shadow: 0 0 0 0 transparent
    }
}

#step-card { 
    border: 2px solid red;
}

