@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Inter:wght@200;300;400;500;600;700;800;900&display=swap");
@import url("./styles/intro-js-lib.css");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "DM Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.loader {
  border-top-color: rgba(37, 99, 235, var(--tw-border-opacity));
  -webkit-animation: spinner 1s linear infinite;
  animation: spinner 1s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ql-editor{
  width: 100%;
  height: 100%;
  outline: none;
  padding: 0.5rem 0.75rem;
  font-size: 14px;
  position: relative;
  overflow-y: auto;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.app-container,
.sidebar {
  transition: margin-left 0.25s, margin-right 0.25s, width 0.25s, flex 0.25s,
    -webkit-flex 0.25s;
}

.create-contact-sidebar-wrapper {
  width: 30vw;
}
.bm-cross-button .bm-cross {
  background-color: #cfd7df;
  outline: none !important;
  height: 25px;
}
.bm-cross-button .bm-cross:hover {
  background-color: #ddd;
}
#react-burger-menu-btn {
  outline: none !important;
}
.bm-cross-button {
  position: absolute;
  width: 32px;
  height: 32px;
  right: 50px !important;
  top: 50px !important;
}
#react-burger-cross-btn {
  outline: none !important;
}
.bm-item {
  outline: none !important;
}
button {
  outline: none !important;
}

.form-tick:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: 50%;
  background-repeat: no-repeat;
}

.campaign-status-live {
  color: white;
  background-color: rgb(15, 155, 57);
}

.campaign-status-idle {
  color: white;
  background-color: rgb(255, 154, 2);
}

.form-status-live {
  color: white;
  background-color: rgb(15, 155, 57);
}

.form-status-paused {
  color: white;
  background-color: rgb(255, 154, 2);
}

/* #campaign-confirmation-preview-wrapper {
  width: 450px;
  height: 500px;
  padding: 0;
  overflow: hidden;
}
#campaign-preview-frame {
  width: 600px !important;
  height: 720px !important;
  border: 0px;
}
#campaign-preview-frame {
  zoom: 0.75;
  -moz-transform: scale(0.75);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.75);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.75);
  -webkit-transform-origin: 0 0;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  #campaign-preview-frame {
    zoom: 1;
  }
} */
.react-datepicker__header {
  background-color: #fff;
  border-radius: 4px;
}
body {
  transition: opacity 0.25s linear, transform 0.4s;
  -webkit-font-smoothing: antialiased;
}

.PhoneInputCountry {
  width: 54px !important;
}

.PhoneInputCountrySelect {
  width: 100% !important;
}
.PhoneInputCountryIcon {
  width: 36px !important;
  min-height: 30px !important;
  min-width: 30px !important;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  background: none !important;
}
.PhoneInputCountrySelectArrow {
  margin-left: 10px !important;
}
.PhoneInput {
  display: flex !important;
  -webkit-align-items: center !important;
  align-items: center !important;
}
.PhoneInputInput {
  padding: 1em 1em;
  outline: none !important;
}

.react-tel-input {
  background: #ffffff;
  border: 1px solid #dce0e4;
  box-sizing: border-box;
  border-radius: 8px;
  min-height: 48px;
}

.react-tel-input .form-control {
  border: none !important;
  width: 100% !important;
  height: 48px !important;
  border: none !important;
  padding-left: 58px !important;
}
.react-tel-input .form-control:focus {
  border: #2563eb !important;
}
.flag-dropdown {
  border: none !important;
}
.flag-dropdown .selected-flag {
  border: none !important;
  background: #ececec !important;
  width: 48px !important;
  padding-left: 14px !important;
}
.search-emoji {
  display: none !important;
}

/* Paginator Select */
.gz-pagn-select-dropdown__control {
  height: 28px;
  min-width: 70px !important;
  background: rgba(242, 242, 242, 0.6) !important;
  border: none !important;
  border-radius: 8px !important;
  padding: 0px 8px;
  cursor: pointer;
  outline: none !important;
}
.gz-pagn-select-dropdown__placeholder {
  min-width: 14px;
  font-size: 13px !important;
  color: #586474 !important;
}
.gz-pagn-select-dropdown__value-container {
  padding: 0 !important;
  margin-right: 4px;
}
.gz-pagn-select-dropdown__indicator {
  padding: 0 !important;
}
.gz-pagn-select-dropdown__indicator svg {
  fill: #84878a;
  width: 16px !important;
}
.gz-pagn-select-dropdown__indicator-separator {
  display: none !important;
}
.gz-pagn-select-dropdown__menu {
  z-index: 100 !important;
  border-radius: 8px !important;
  text-align: center;
}
.gz-pagn-select-dropdown__menu-list {
  padding: 2px 0px 2px 0px !important;
}
.gz-pagn-select-dropdown__single-value {
  font-size: 14px !important;
}
.gz-pagn-select-dropdown__option {
  font-size: 14px !important;
}

/* Normal select */
.gz-select-dropdown__control {
  border-width: 1px !important;
  border-radius: 8px !important;
  outline: none !important;
  cursor: pointer;
  height: 48px;
  border: 1px solid #dce0e4;
  box-sizing: border-box;
}
.gz-select-dropdown__control--is-focused {
  border-width: 1px !important;
  outline: none !important;
  border: none !important;
}
.gz-select-dropdown__placeholder {
  font-size: 13px !important;
  color: #586474 !important;
}
.gz-select-dropdown__value-container {
  margin-right: 4px;
  padding: 2px 16px;
}
.gz-select-dropdown__indicator {
  padding-right: 0px 8px !important;
}

.gz-select-dropdown__indicator-separator {
  display: none !important;
}
.gz-select-dropdown__menu {
  z-index: 100 !important;
  border-radius: 6px !important;
}
.gz-select-dropdown__menu-list {
}
.gz-select-dropdown__single-value {
  font-size: 14px !important;
}
.gz-select-dropdown__option {
  font-size: 14px !important;
}
.gz-select-dropdown__option:hover {
  background: #eef3fd !important;
}

.gz-select-dropdown__option--is-selected {
  background-color: #2563eb !important;
}

.gz-input-mselect__menu {
  z-index: 100 !important;
  font-size: 15px !important;
  cursor: pointer !important;
}

.rs-uploader-trigger input[type="file"] {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
}

.gz-csv-input .csv-label {
  margin-right: 2em;
}
.rs-picker-menu {
  z-index: 100001;
}
.form-tick:checked {
  color: #2563eb;
}
@media (max-width: 700px) {
  #campaign-preview-frame html {
    zoom: 0.5;
  }
}

.hdlui-dialog-container {
  z-index: 1200;
}

#checkbox-switch:checked ~ .btn-switch::before {
  transition: 0.3s;
  transform: translateX(23px);
}

.btn-switch-unchecked {
  background-color: #c1c1c2;
  padding: 4px;
}

.btn-switch-unchecked::before {
  content: "";
  display: block;
  width: 17px;
  height: 17px;
  border-radius: 6px;
  background-color: white;
  transition: 0.3s;
  transform: translateX(0);
}

.btn-switch-checked {
  background-color: #2563eb;
  padding: 4px;
}

.btn-switch-checked::before {
  content: "";
  display: block;
  width: 17px;
  height: 17px;
  border-radius: 6px;
  background-color: white;
  transition: 0.3s;
  transform: translateX(22px);
}
.fab-container ul {
  left: 0;
  right: 0;
  text-align: center;
  position: absolute;
  bottom: 80px;
  margin: 0;
  visibility: hidden;
  list-style-type: none;
}
.fab-container.fab-visible ul {
  visibility: visible;
}
.fab-action-btn {
  color: #fff;
  position: relative;
  overflow: hidden;
  z-index: 1;
  line-height: 40px;
  padding: 0;
  background-color: #294ed8;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  cursor: pointer;
  vertical-align: middle;
}
.fab-item {
  display: inline-block;
  color: #fff;
  position: relative;
  overflow: hidden;
  z-index: 1;
  line-height: 40px;
  padding: 0;
  background-color: #294ed8;
  border-radius: 50%;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  cursor: pointer;
  list-style-type: none;
  vertical-align: middle;
}
#signup-form-preview {
  height: calc(100vh - 10px);
}
#panel-scroll {
  height: calc(100vh - 200px);
}
#signup-form-editor {
  height: calc(100vh - 120px);
}
#campaign-text-editor {
  white-space: pre-wrap;
  height: calc(100vh - 200px);
  width: 80%;
}
.campaign-text-editor #editor {
  height: calc(100vh - 240px);
  border: none;
  outline: none;
  overflow-y: auto;
  overflow-x: auto;
  word-break: break-all;
  font-family: Roboto,RobotoDraft,Helvetica,Arial,sans-serif;
  font: small/ 1.5 Arial,Helvetica,sans-serif;
  letter-spacing: normal;
  color: #222;
  width: 100%;
  /* max-width: calc(100vw - 53vw); */
}
.txt-clck-tchse {
  position: relative;
}
.short-code-blk{
  width: 300px;
  padding: 1em;
  margin: 0 1em;
}
.group:hover .group-hover\:block {
  display: block;
}

.select-tags-input {
  border-radius: 8px;
  overflow: hidden;
}

.custom-scroller::-webkit-scrollbar-track {
  background: #ececec;
  border-radius: 10px;
}
.custom-scroller::-webkit-scrollbar-thumb {
  background: #9fa1a5;
  border-radius: 10px;
  opacity: 0.6;
}
.custom-scroller::-webkit-scrollbar {
  width: 5px;
  height: 151px;
}

.gz-basic-input::placeholder {
  font-size: 14px;
}

.h-fit {
  height: fit-content;
}
.gz-sl-drp-imp-csra.gz-select-dropdown__control {
  height: 40px !important;
}
#import-dropzone.dragover {
  background-color: #dee0e4 !important;
}
::selection {
  color: white;
  background: #2563eb;
}
.svg-cl-group-container:hover * {
  fill: #2563eb;
}
.__react_component_tooltip.show {
  opacity: 1 !important;
}
.rc_tooltip-white {
  background: white !important;
  border: 1px solid rgb(237, 228, 228) !important;
  border-radius: 12px !important;
  padding: 16px 22px !important;
}
.rc_tooltip-white::before {
  border-left: 8px solid #cac6c6 !important;
}

.user-opt-tb-container {
  border-radius: 7px !important;
  padding: 0 !important;
  background: #ffffff !important;
  border: 1px solid #edeff1 !important;
  box-sizing: border-box !important;
  box-shadow: 0px 8px 40px rgba(11, 13, 63, 0.08) !important;
}
.user-opt-tb-container::before {
  left: 80% !important;
  border-bottom: 8px solid #edeff1 !important;
}

.user-opt-tb-container::after {
  left: 80% !important;
}
.select-tags-input__placeholder {
  font-size: 13px;
}
.select-tags-input__menu-notice--no-options {
  font-size: 13px;
}
.select-tags-input__indicators {
  color: #7e7c7c;
}

#contact-sources-chart .apexcharts-canvas {
}
.apexcharts-title-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #98989a;
}
.apexcharts-xaxis-label {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #9ca3af;
}
.apexcharts-yaxis-label {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #9ca3af;
}
.rct-select-multi-input__control {
  background: #ffffff;
  opacity: 0.6;
  border: 1px solid #dce0e4;
  box-sizing: border-box;
  border-radius: 8px !important;
}
.rct-select-multi-input__placeholder {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #586474;
  opacity: 0.6;
}
.rct-select-multi-input__control {
  height: 40px !important;
}
.rct-select-multi-input__indicators {
  display: none !important;
}
.rct-select-multi-input__multi-value {
  background-color: #dce0e4 !important;
  opacity: 0.8 !important;
  border: 1px solid #dce0e4 !important;
  border-radius: 8px !important;
}
.rct-select-multi-input__multi-value__label {
  color: #06152d !important;
}

.hd-scroll {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.hd-scroll::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.table {
  border-collapse: collapse;
  border: 1px solid #cac4c4;
}
@media (min-width: 60em) {
  .wrapper__navigation::-webkit-scrollbar {
    width: 0.4rem;
  }
}
@media (min-width: 60em) {
  .wrapper__navigation::-webkit-scrollbar-thumb {
    background: rgba(28, 49, 58, 0.054);
    border-radius: 0.18rem;
  }
}
.checkout-status {
  border-radius: 50px;
  display: inline-block;
  padding: 2px 10px;
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
}
.checkout-status.abandoned,
.checkout-status.fraud {
  background: #fff0e9;
  border: 1px solid #fff0e9;
  color: #ff7f45;
}
.checkout-status.recovered,
.checkout-status.completed {
  background-color: #e0f7e9;
  color: #18c364;
}
.checkout-status.in-progress,
.checkout-status.pending {
  background-color: rgba(1, 152, 224, 0.2);
  color: #0198e0;
}
.checkout-status.live {
  background-color: #e6f9ff;
  color: #17a0ca;
}
.checkout-status.recoverable {
  background-color: #f4e2ff;
  color: #5e137d;
}

@media (max-width: 991.98px) {
  .cart-filter {
    padding: 0 15px;
  }
  .cart-filter .badge {
    font-size: 12px !important;
    padding: 5px 10px !important;
  }
  .checkout-status {
    font-size: 12px;
    padding: 5px 10px;
  }
}
.container {
  width: calc(100% - 20%);
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

.h1,
h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  .h1,
  h1 {
    font-size: 2.5rem;
  }
}
.h2,
h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  .h2,
  h2 {
    font-size: 2rem;
  }
}
.h3,
h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  .h3,
  h3 {
    font-size: 1.75rem;
  }
}
.h4,
h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  .h4,
  h4 {
    font-size: 1.5rem;
  }
}
.h5,
h5 {
  font-size: 1.25rem;
}
.h6,
h6 {
  font-size: 1rem;
}
.iv-item-bt-1 div {
  border-top: 1px solid rgb(228, 228, 228);
}

.iv-item div,
.iv-item-b-0 div {
  font-size: 13px;
  color: black;
  font-weight: bold;
  border-bottom: 1px solid rgb(228, 228, 228);
}

.iv-item-b-0 div {
  border: 0 !important;
  padding-top: 5px !important;
  padding-bottom: 0 !important;
}
.options-tab-container {
  border-bottom: 1px solid #c8ced3;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 rgba(43, 43, 43, 0.1),
    0 11px 6px -7px rgba(43, 43, 43, 0.1);
}
.options-tab-container .option-item {
  cursor: pointer;
  padding: 13px;
  text-align: center;
}
.options-tab-container .option-item:hover {
  opacity: 0.8;
}
.options-tab-container .active {
  border-bottom: 1px solid #595dd4;
}
.options-tab-content {
  padding: 8px 8px;
  height: calc(100vh - 29vh);
  position: relative;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 rgba(43, 43, 43, 0.1),
    0 11px 6px -7px rgba(43, 43, 43, 0.1);
  margin-top: 8px;
}
.options-tab-content .list-container {
  --column-gutter: 24px;
  --row-gutter: 24px;
  --columns: 3;
  height: 100%;
  padding: 15px;
  min-width: 100%;
  overflow-y: auto;
}
.options-tab-content .flash-image-item {
  cursor: pointer;
  border: 1px solid #ddd;
  padding: 3px;
  height: fit-content;
  margin-bottom: 6px;
  position: relative;
}
.options-tab-content .flash-image-item img {
  width: 100%;
}
.options-tab-content .flash-image-item:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #333;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.options-tab-content .flash-image-item:hover:before {
  opacity: 0.2;
}
.options-tab-content .flash-image-item:hover:after {
  opacity: 0.6;
}
.gzform-image-upload .searchButton {
  width: 40px;
  height: 48px;
  border: 1px solid #171874;
  background: #171874;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 16px;
  margin-left: -10px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.title-long-text {
  font-size: 20pt;
  color: #ccc;
  padding: 0 10px;
  font-family: Roboto, Arial, sans-serif;
}
.simple-text {
  padding: 15px 0 5px;
  font-weight: bold;
  font-size: 13px;
  color: #ccc;
  font-family: Roboto, Arial, sans-serif;
  text-align: center;
}

.upload-btn {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-color: #4d90fe;
  background-image: -webkit-linear-gradient(top, #4d90fe, #4787ed);
  background-image: -moz-linear-gradient(top, #4d90fe, #4787ed);
  background-image: -ms-linear-gradient(top, #4d90fe, #4787ed);
  background-image: -o-linear-gradient(top, #4d90fe, #4787ed);
  background-image: linear-gradient(top, #4d90fe, #4787ed);
  border: 1px solid #3079ed;
  color: #fff;
  border-radius: 2px;
  cursor: default;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  margin-right: 16px;
  height: 30px;
  line-height: 29px;
  min-width: 54px;
  outline: 0;
  padding: 0 8px;
}
.upload-btn:hover {
  opacity: 0.9;
}
.upgrade-page {
}
.campaigns-page-fresh,
.teams-page-fresh,
.signupform-fresh {
  position: relative;
  z-index: 1;
}
.teams-page-fresh::before,
.campaigns-page-fresh::before {
  content: "";
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-filter: blur(8px);
  filter: blur(8px);
  z-index: -1;
}
.campaigns-page-fresh::before { 
  background-image: url("https://broadcast-app-assets.dev.app.gozen.io/signupfrom-fresh.png");
}
.teams-page-fresh::before { 
  background-image: url("https://broadcast-app-assets.dev.app.gozen.io/teams-upgrade.png");
}
.signupform-fresh::before {
  content: "";
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-filter: blur(8px);
  background-image: url("https://broadcast-app-assets.dev.app.gozen.io/CLmI3gJ-min.png");
  filter: blur(8px);
  z-index: -1;
}
.indusrty{
  /* width: 23%; */
  width: 92% !important;

}

.list-industry::-webkit-scrollbar{
  width: 6px;
  padding-top: 20px;
}
.list-industry::-webkit-scrollbar-track{
  background-color: #F9FAFB;
}
.list-industry::-webkit-scrollbar-thumb{
  border-radius: 10px;
  background: rgba(138, 146, 158, 0.4);
  cursor: auto;
  
}
.list-industry::-webkit-scrollbar-thumb:hover{
  border-radius: 10px;
  background: rgba(138, 146, 158, 0.7);
  
}
.is-capitalized {
  text-transform: capitalize;
}
@media (max-width: 1300px) {
  .crd-gz-logo-container {
    margin-bottom: 44px;
    max-width: 200px;
  }
}
@media (min-width: 1301px) {
  .crd-gz-logo-container {
    margin-bottom: 88px;
    max-width: 300px;
  }
}

