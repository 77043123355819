.App {
  text-align: center;
} 

.App-logo { 
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
/* :root {
  --InputRangecolor:rgb(197, 154, 236);
} */
input[type='range'] {
  -webkit-appearance: none;
  --InputRangecolor:rgb(197, 154, 236);
  appearance: none;
  height: 5px;
  border-radius: 999px;
  background:var(--InputRangecolor);

}

.Applist{
  min-width: 420px ;
  display: grid;
  grid-template-columns: auto auto auto;
  position: absolute ;
  gap: 16px;
  top:70px;
  right: 24px;
  z-index: 100;
  padding: 18px;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 10px 50px rgba(11, 13, 63, 0.08);
}

.appcontainer{
  display: flex;
  flex-direction: column;
  margin: 0px auto;
  padding: 12px;
  border: 1.5px solid #EDEFF1;
  border-radius: 12px ;
  justify-content: center;
  width: 100%;
  min-width: 125px;
  cursor: pointer;
  transition: all;
  transition-duration: 500ms;
}

.projectpostthumbnail{
  position: relative;
  height: 175px;
  overflow: hidden;
  border-radius: 12px;
  z-index: 0;

  /* width: 290px;
  max-height: 175px; */
}
.projectpostcontainer{
  position: relative;
  top:0px;
  background-color:white;
  padding: 1rem;
  border-radius: 12px;
  transition: all 0.5s ;
  cursor: pointer;
  /* z-index: -2; */

}

.projectpostthumbnailimg{
  position: relative;
  width: 100%;
  height: 175px;
  border-radius: 12px;
  object-fit: fill;
  transform: scale(1);
  transition: all 0.5s ;
  z-index: -1;

}

.projectpostcontainer:hover{
  /* box-shadow: 0px 0px 45px #535454 ; */
  top:-10px;
}
.projectpostcontainer:hover .projectpostthumbnailimg{
  transform: scale(1.2);        
}

/* .projectpostthumbnail img{
  transform: scale(1.2);        
} */

.appcontainer:hover{
  border-color: #2563EB;
  box-shadow: 0px 10px 50px rgba(11, 13, 63, 0.08);

}
.activeBundle {
  border: 1.5px solid #2563EB!important;
  color: #2563EB!important;
}
.appIcon{
  width:35px;
  height: 55px;
  padding: 6px 0px;
  margin: 0px auto;
}

.apptitle {
  margin:  0px auto;
  font-size: 14px;
  font-weight: 600;
  color: black;
  padding: 6px auto;
  text-align: center;
}
/* 
canvas{
  margin: auto;
} */
.konvajs-content{
     /* margin: auto; */
     touch-action: none;
}

input[type="range"]::-webkit-slider-thumb {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design rgb(147 51 234 */
  height: 15px;
  width: 15px;
  background: rgb(130, 111, 148);
  border-radius: 50%;
  border: none;

  /* box-shadow: -407px 0 0 400px #f50; emove this line */
  transition: .2s ease-in-out;
}

/* Thumb: Firefox */
input[type="range"]::-moz-range-thumb {
  height: 15px;
  width: 15px;
  background-color: #f50;
  border-radius: 50%;
  border: none;

  /* box-shadow: -407px 0 0 400px #f50; emove this line */
  transition: .2s ease-in-out;
}
input[type="range"]::-webkit-slider-thumb {
  background:var(--InputRangecolor);


  box-shadow: 0 0 0 10px rgba(147, 51, 234, .2)
}
input[type="range"]::-webkit-slider-thumb:hover {
  background:var(--InputRangecolor);
  cursor: pointer;
  box-shadow: 0 0 0 10px rgba(147, 51, 234, .2)
}
input[type="range"]:active::-webkit-slider-thumb {
  background:var(--InputRangecolor);

  box-shadow: 0 0 0 13px rgba(147, 51, 234, .2)
}
input[type="range"]:focus::-webkit-slider-thumb {
  background:var(--InputRangecolor);

  box-shadow: 0 0 0 13px rgba(147, 51, 234, .2)
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.gamecontainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: fit-content; */
  margin: 4% auto;
  background: #FAFCFE;
  padding: 2rem;
  /* border-radius: 26px;   */
  position: relative;
  width: 100%;
  /* border: 1px solid gray; */
}
.gamepanel {
  width: 100%;
  display: grid;
  /* grid-gap:2rem; */
  /* grid-template-rows: auto auto auto;
  grid-template-columns: auto auto auto auto; */
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.noselect::-moz-selection {   background: #FAFCFE; }
.noselect::selection {  background: #FAFCFE; }

.gamecard {
      /* background: #DCE0E4; */
      border-radius: 20px;
      position: relative;
      /* min-height: 190px; */
      cursor: pointer;
      box-shadow: 0px 0px 2px 0px gray;
      transition: transform 0.6s;
      transform-style: preserve-3d;
      padding: 4rem;
}


.newgamecard {
  /* background: #DCE0E4; */
  border-radius: 20px;
  /* border-radius: 16px; */

  position: relative;
  /* min-height: 190px; */
  cursor: pointer;
  box-shadow: 0px 0px 2px 0px gray;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  padding: 2rem;
}

.FindFlowgamecard {
  position: relative; 
  cursor: pointer;
  box-shadow: 0px 0px 2px 0px gray;
  transition: transform 0.6s;
  transform-style: preserve-3d;  
}
.activecard{
  transform: rotateY(180deg);
}.deactivecard{
  transform: rotateY(0deg);
}
.gamecardmobile {
  /* min-height: 80px !important; */
  /* min-width: 80px !important; */
  /* border-radius: 10px !important; */
  border-radius: 16px !important;
  padding: 1.6rem !important;
}

.gamecardFBmodile{
  border-radius: 14px !important;
  /* padding: 1rem; */

}

.gamecardfront,.gamecardback{
  position: absolute;
  top:0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  display: flex;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

}.gamecardinner{
  display: flex;
  margin: auto;
}
.gamecardback{
  background-color: white;
  transform: rotateY(180deg);
}
.gameWelcomePage{
  position: absolute;
  z-index: 10;
  background-color: rgba(250, 250, 250, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  left: 0;
}
.but{
  padding: 0.75rem 1.5rem;
  font-size: 18px;
  font-weight: 600;
  border-radius: 10px;
  border: 1px solid rgb(37, 99, 235);
  margin: auto;
  display: flex;
  color: white;
  cursor: pointer;
  background-color: rgb(37, 99, 235);
}
.welcomepageBlock{
  font-size: 18px;
  font-weight: 600;
  margin: auto;
  width: fit-content;
  /* width: 100%; */
  padding: 2rem 3rem;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 0px 5px rgba(80, 80, 80, 0.83);
}
.center{
  text-align: center;
  padding: 0.5rem 0px;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.selectedMatchPair{
  background-color: rgb(233 244 255);
  border: 3px solid #06d455ad;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}


.divScroll {
  /* overflow: scroll; */
/*  background-color: transparent; */
}
.divScroll::-webkit-scrollbar {
   width: 7px;
}

.divScroll::-webkit-scrollbar-track {
   border-radius: 10px;
   background-color: #ededed;
}

.divScroll::-webkit-scrollbar-thumb {
   border-radius: 10px;
   background: #bbbbbb;
}



.gameSimpleScroll {
  /* overflow: scroll; */
  background-color: transparent;
}
.gameSimpleScroll::-webkit-scrollbar {
   width: 7px;
   height: 7px;

}

.gameSimpleScroll::-webkit-scrollbar-track {
   border-radius: 10x;
   background-color: #ededed;
}

.gameSimpleScroll::-webkit-scrollbar-thumb {
   border-radius: 10x;
   background: #bbbbbb;
}

/* Hiding the error boundary */
#webpack-dev-server-client-overlay{
  display: none !important;
  position: relative !important; 
  width: 95% !important;
  height: 1px !important;
  border: none !important;
  z-index: 1 !important;
}


.engage-sideTab-parent {
  transition: 1s;
  /* width: 50%; */
  position: absolute;
  height: 100%;
  top: 0px;
  /* background-color: aqua; */
  /* opacity: 0; */
}

.engage-sideTab-btn {
  cursor: pointer;
  position: absolute;
  top: 25%;
  padding: 10px;
  min-width: 80px;
  max-width: 350px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: none;
  font-weight: bold;
  width: max-content;
}

.engageLoadingDiv {
  /* border-radius: 15px; */
  width: 100%;
  height: 100%;
  background: #eaeaea;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
}

.engageLoading {
  display: inline-block;
  width: 3%;
  max-width: 32px;
  min-width: 24px;
  aspect-ratio: 1/1;
  border: 3px solid rgb(215 215 215);
  border-radius: 55%;
  border-top-color: #2563eb;
  border-right-color: #2563eb;
  animation: engageSpin 1s ease-in-out infinite;
  -webkit-animation: engageSpin 1s ease-in-out infinite;
  margin-bottom: 2%;
}

@keyframes engageSpin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes engageSpin {
  to {
    -webkit-transform: rotate(360deg);
  }
}


.SimpleScratchCard canvas{
  touch-action: none;
  cursor: pointer;
  border-radius: 9px;
}

.scratchCardStyle{
  position: absolute;
  z-index: 11;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: rgb(163 163 163 / 55%);
}
.scratchCardParent{
  touch-action: none;
  width: 75%;
  min-width: 290px;
  max-width: 579px;
  height: 68%;
  min-height: 250px;
  max-height: 331px;
}
.scratchCardInner{
  width: 100%;
  height: 100%;
  touch-action: none;
  background: white;
  border-radius: 9px;
  aspect-ratio: 1/1;
  padding: 2rem;
}

.engage-close-btn {
  top: 1%;
  right: 1%;
  border: 1px solid lightgray;
  position: absolute;
  cursor: pointer;
  z-index: 16777269;
  background: white;
  border-radius: 50%;
}
.engage-close-btn:hover {
  background-color: #EAF1F3;
}

.toUpData{
  animation-name: moveUpDataAnimation;
  animation-duration: 2s; 
  animation-fill-mode: forwards;
}

@keyframes moveUpDataAnimation {
  0%   { top:3%; opacity:1; }
  100%   { top:-77%; opacity:0.4; }
}


.toDownData{
  animation-name: moveDownDataAnimation;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

@keyframes moveDownDataAnimation {
  0%   { top:83%; opacity:0.4; }
  100%   { top:3%; opacity:1; }
}


.engage-broken-text{
  color: #06152D;
  font-weight: 700;
  font-size: 21px;
  margin-top: 10px;
}


@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px); 
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInUp {
  animation: fadeInUp 500ms ease-out; 
}
