.engage-popupParent {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 16777268;
  top: 0;
  left: 0;
  display: none;
}
.engage-container {
  position: relative;
  z-index: 16777268;
}
#engage-block {
  height: 100%;
}
.engage-container-full {
  position: absolute;
  align-content: center;
  top: 0px;
  width: 100%;
  height: 100%;
  bottom: 0px;
  display: flex;
}
#engageIframe {
  border: none;
  background: white;
  z-index: 16777268;
}
.engage-close-btn {
  top: 1%;
  right: 1%;
  border: 1px solid lightgray;
  position: absolute;
  cursor: pointer;
  z-index: 16777269;
  background: white;
  border-radius: 50%;
}
.engage-close-btn:hover {
  background-color: #EAF1F3;
}
/* PopUp & fullPage */
.engage-popup,
.engage-fullPage {
  display: flex;
  margin-left: auto;
  border-radius: 30px;
  margin-right: auto;
  opacity: 0;
  min-width: 365px;
}
.engage-fullPage {
  animation: fadein 1.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.engage-popup {
  animation: fadein2 1.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes fadein2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
 /* Popover */
.engage-popover-btn {
  display: flex;
  justify-content: center;
  max-width: 280px;
  border-radius: 10px;
  min-width: fit-content;
  word-wrap: break-word;
  height: fit-content;
  color: white;
  font-weight: 600;
  position: fixed;
  cursor: pointer;
  padding: 1.25rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.engage-popover {
  position: absolute;
  bottom: 0;
  border-radius: 5%;
  min-width: 365px;
  padding: 3px;
  transform: translateY(100%);
  opacity: 0;
}
.engage-popover.open {
  animation: animatePopoverOpen 0.8s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.engage-popover.close {
  animation: animatePopoverClose 0.8s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes animatePopoverOpen {
  0% {
    opacity: 0.7;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
@keyframes animatePopoverClose {
  0% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}
/* .container{
  display: grid;
  grid-auto-rows: minmax(min-content, max-content);
  grid-template-columns: auto 1fr;
}
.vertical{
  transform: rotate(180deg);
  writing-mode: vertical-lr;
  text-align: center;
} */
/* SideTab */
.engage-sideTab{
  height: 100%;
  width: 100%;
  position: relative;
  grid-template-rows: auto;
  display: grid;
  grid-auto-rows: minmax(min-content, max-content);
  grid-template-columns: auto 1fr;
}
.engage-sideTab-Frame{
  width:100%;
}
.engage-sideTab-vertical{
  height: 100%;
  display: grid;
  align-content: center;
  transform: rotate(180deg);
  /* writing-mode: vertical-lr; */
  text-align: center;
}
/* .engage-sideTab-btn {
  display: flex;
  justify-content: center;
  height: fit-content;
  color: white;
  font-weight: 600;
  overflow-x: hidden;
  position: absolute;
  bottom: 20%;
  cursor: pointer;
  padding: 1rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
} */
.engage-sideTab{
  right: -100%;
}
/* .engage-sideTab-btn{
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
} */
.engage-sideTab.open {
  animation: animateRightSideOpen 0.8s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.engage-sideTab.close {
  animation: animateRightSideClose 0.8s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes animateRightSideOpen {
  0% {
   right:-100%;
  }
  100% {
   right:-50%;
  }
}
@keyframes animateRightSideClose {
  0% {
   right:-50%;
  }
  100% {
   right:-100%;
  }
}
